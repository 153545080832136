import FooterIngev from './components/FooterIngev/FooterIngev';
import MainCointainer from './components/MainCointainer/MainCointainer';
import ResultData from './context/ResultData';
import HeaderIngev from './components/HeaderIngev/HeaderIngev';
function App() {
  return (
    <ResultData>
      <HeaderIngev/>    
      <MainCointainer/>
      <FooterIngev/>
    </ResultData>    
  );
}

export default App;
