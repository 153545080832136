import React from "react";
import facebook from '../../img/facebook.svg'
import linkedin from '../../img/linkedin.svg'
import youtube from '../../img/youtube.svg'
import instagram from '../../img/instagram.svg'
import tiktok from '../../img/tiktok.svg'

const FooterIngev = () =>{
    return(
        <footer className="footer-ingev">
            <div className="footer-container">
                <div className="footer-schedule">
                    <p style={{fontWeight:"bold",fontSize:"18px",textTransform:"uppercase"}}>Horario de atención</p>
                    <div>
                        <p style={{fontWeight:"bold"}}>Lunes a Viernes:</p>
                        <p>7am - 12 pm y 2pm - 6 pm</p>
                        <p style={{fontWeight:"bold"}}>Sabados:</p>
                        <p>7am - 12pm</p>
                    </div>
                </div>
                <div className="footer-address">
                    <p style={{fontWeight:"bold",fontSize:"18px",textTransform:"uppercase"}}>Dirección:</p>
                    <p style={{lineHeight:"1.5"}}>Calle 60 No 9 – 78 Int. 305 Bogotá. DC <br/>
                        Calle 15 No 18 – 13 Int. 207 Yopal-CAS</p>
                    <p style={{fontWeight:"bold",fontSize:"18px",textTransform:"uppercase"}}>Mail:</p>
                    <p><a href="mailto:contacto@ingev.com.co">contacto@ingev.com.co</a></p>
                </div>
                <div className="follow">
                    <p style={{fontWeight:"bold",fontSize:"18px",textTransform:"uppercase"}}>Siguenos en:</p>
                    <div className="footer-followus">
                        <a href="https://www.facebook.com/ingev.com.co" className="social-icons"><img src={facebook} className="filter-white" alt="facebook"/></a>
                        <a href="https://www.linkedin.com/company/ingev-sas/" className="social-icons"><img src={linkedin} className="filter-white" alt="linkedin"/></a>
                        <a href="https://www.youtube.com/channel/UCgCLRXO1KTd9sPxSHET22rw" className="social-icons"><img src={youtube} className="filter-white" alt="youtube"/></a>
                        <a href="https://www.instagram.com/ingenioverde_sas/" className="social-icons"><img src={instagram} className="filter-white" alt="instagram"/></a>
                        <a href="https://vm.tiktok.com/ZMNk2j2Yc/" className="social-icons"><img src={tiktok} className="filter-white" alt="tiktok"/></a>
                    </div>
                </div>                
            </div>            
            <span>Copyright © 2021 INGEV S.A.S.</span>
        </footer>
    )
}

export default FooterIngev