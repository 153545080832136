import React, {useCallback} from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import UserVariables from "../UserVariables/UserVariables";
import GeneralVariables from "../GeneralVariables/GeneralVariables";
import { motion } from "framer-motion";
import '../../css/main.css'
import configParticles from "../../assets/particlesjs-config.json";


const MainCointainer = () => {


    const particlesInit = useCallback(async (engine) => {        
        await loadFull(engine);
    }, []);

    
    const particlesLoaded = useCallback(async (container) => {
    }, []);
    
    return (
        <div className="main-container">
            <Particles  className="particles" canvasClassName="background-animated" id="tsparticles" init={particlesInit} loaded={particlesLoaded} options={configParticles}/>      
            <motion.h1   
                style={{zIndex:"1 !important"}}
                animate={{
                    x:[-500,100,-50,0]
                }}
                transition={{
                    duration: 2,
                    ease: "easeInOut"
                }}
            >Cotiza tu proyecto</motion.h1>
            <section style={{display:"flex",zIndex:"1"}} className="seccion-cotiza">
            <UserVariables/>
            <GeneralVariables/>
            </section>                              
        </div>
    )
}

export default MainCointainer