import React from "react";
import { useEffect, useState, useContext } from "react";
import { Results } from "../../context/ResultData";

const GeneralVariables = () =>{ 

    const{novacio, resultados_} = useContext(Results);
    const [verifica, setVerifica] = useState(false);
    const flag = Object.keys(resultados_).length
    
    
    
    useEffect(()=>{
        setVerifica(false)
    },[flag])

    useEffect(()=>{        
        new Promise((resolve)=>{
            setTimeout(()=>{
                resolve(true);
            },2000)
        }).then(respuesta=>setVerifica(respuesta)) 
            
    },[resultados_])
      

    return(
        flag === 0 ?
        (
            <h2 style={{textTransform:"uppercase"}}>Llena los datos para cotizar</h2>
        ):(
            verifica === false ? (
                <h2 style={{textTransform:"uppercase"}}>Generando cotización</h2>

            ):(          
                novacio === false ? (
                    <article className="form-container">
                        <h2 style={{textTransform:"uppercase"}}>El resultado de la cotización es:</h2>
                        <div className="resultados">                        
                            <p className="resultados-p">Consumo mensual promedio: {new Intl.NumberFormat().format((resultados_.cotizacion.consumo_mensual_promedio))} kW/h</p>
                            <p className="resultados-p">Equivalente a: ${new Intl.NumberFormat().format((resultados_.cotizacion.equivalente_a))} COP</p>
                            <p className="resultados-p">Porcentaje de ahorro: {new Intl.NumberFormat().format((resultados_.cotizacion.porcentaje_de_ahorro))} %</p>
                            <p className="resultados-p">Área disponible necesaria: {new Intl.NumberFormat().format((resultados_.cotizacion.area_disponmible_necesaria))} m2</p>
                            <p className="resultados-p">Potencia requerida para el ahorro: {new Intl.NumberFormat().format((resultados_.cotizacion.potencia_requerida_para_el_ahorro))} kW</p>
                            <p className="resultados-p">Valor ahorrado mensual: ${new Intl.NumberFormat().format((resultados_.cotizacion.valor_ahorrado_mensual))} COP</p>
                            <p className="resultados-p">Valor ahorrado anual: ${new Intl.NumberFormat().format((resultados_.cotizacion.valor_ahorrado_anual))} COP</p>
                            <p className="resultados-p">Precio a pagar con energía solar: ${new Intl.NumberFormat().format((resultados_.cotizacion.precio_a_pagar_con_energia))} COP</p>
                            <p className="resultados-p">Valor estimado del proyecto: ${new Intl.NumberFormat().format((resultados_.cotizacion.valor_estimado))} COP</p>
                            <p className="resultados-p">Retorno de inversión: {new Intl.NumberFormat().format((resultados_.cotizacion.retorno_de_inversion))} Años</p>
                        </div>
                    </article> 
                ):(
                    <h2 style={{textTransform:"uppercase"}}>Por favor llena todos los campos</h2>
                )
                
            )                       
        )
    )
}
export default GeneralVariables