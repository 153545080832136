import React from "react";
import { useContext,useState } from "react";
import { Results } from "../../context/ResultData";
import { motion } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";


const UserVariables = ()=>{

    const{setVariblesU,cotizacion_,setNovacio} = useContext(Results);
    const[captchaflag, setCaptchaflag]=useState(null);    

    const HandleChange = () =>{
        setCaptchaflag(true)
    }
     
    const submit = (e)=>{
        e.preventDefault()
        const data = Array.from(new FormData(e.target))        
        if (data[0][1] && data[1][1] && data[2][1] && data[3][1] && data[5][1]){
            if (captchaflag === true)
            {                
                setNovacio(false)
                cotizacion_(Object.fromEntries(data))
                setVariblesU(Object.fromEntries(data))                
            }else{
                setCaptchaflag(false)
            }
        }else{
            setNovacio(true)
            alert("Por favor llena todos los campos")
        }                
    }
    

    return(
        <motion.div     
        animate={{
            x:[-500,100,-50,10,0]
        }}
        transition={{
            duration: 2,
            ease: "easeInOut"
        }}
        className="form-container">
            <form onSubmit={submit}>
            <label className="etiquetas" style={{marginBottom:"10px"}}>Nombre de la empresa:</label>
            <input type="text" id="compay" name="company"/><br/>
            <label className="etiquetas" style={{marginBottom:"10px"}}>Nombre de quien solicita:</label>
            <input type="text" id="name" name="name"/><br/>
            <label className="etiquetas" style={{marginBottom:"10px"}}>Consumo promedio [kw/h]:</label>
            <input type="number" id="consumo" step="0.01" name="consumo"/><br/>
            <label className="etiquetas" style={{marginBottom:"10px"}}>Ubicación (Dirección):</label>
            <input type="text" id="direccion" name="direccion"/><br/>
            <label className="etiquetas" style={{marginBottom:"10px"}}>Ciudad:</label>
            <input type="text" id="city" name="city"/><br/>
            <label className="etiquetas" style={{marginBottom:"10px"}}>Costo del kW/h de energía actual (incluya impuestos):</label>
            <input type="number" id="cost_kvh" step="0.01" name="cost_kvh"/><br/>            
            <label className="etiquetas" style={{marginBottom:"10px"}}>Teléfono:</label>
            <input type="tel" id="phone" name="phone"/><br/>                                                
            <label className="etiquetas" style={{marginBottom:"10px"}}>Correo electrónico</label>
            <input type="email" id="mail" name="mail"/><br/>
            <ReCAPTCHA
                style={{alignSelf:"center"}}
                sitekey="6Lc5hRsiAAAAAGQrihMrYsPJse6wuEEjhD3b6Rts"
                onChange={HandleChange}
            />
            {captchaflag === false && <p style={{textAlign:"center",color:"red"}}>Por favor verifica que no eres un robot</p>}
            <input  className="boton-cotizar" type="submit" value="Cotizar"/>
            </form>
        </motion.div>
    )
}

export default UserVariables