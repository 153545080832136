// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFEe1V02IpOy7IWaoWMjT1vVaP7nLkMDs",
  authDomain: "ingev-calculadora-d0731.firebaseapp.com",
  projectId: "ingev-calculadora-d0731",
  storageBucket: "ingev-calculadora-d0731.appspot.com",
  messagingSenderId: "198395334377",
  appId: "1:198395334377:web:0c5e9453261b14bc406a78"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db