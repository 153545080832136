import React from "react";
import { useState } from "react";
import { createContext } from "react";
import db from "../services";
import { collection, addDoc } from "firebase/firestore";
import config_ from "../config/config.json"

export const Results = createContext();

const ResultData = ({children}) =>{

    const[variablesU, setVariblesU] = useState({});
    const[resultados, setResultados] = useState({});
    const[novacio, setNovacio] = useState(true)
    const [resultados_, setResultados_] = useState({})    
    
    const constantes = {
        impuesto_contr:.2,
        impuesto_ap:.14,
        ahorro_estimado:0.8,
        kilos:1000,
        potencia_panel:545,
        area_panel:2.5312,
        valor_de_peso_1_60:8600,
        valor_de_peso_61_120:5590,
        valor_de_peso_121:4558
    }
    
    const cotizacion_ = async (props) =>{
        try {
            const {city,company,consumo,cost_kvh,direccion,mail,name,phone} = props;
            const consumo_a_cubrir = consumo*constantes.ahorro_estimado;            
            const enero = consumo_a_cubrir/(0.78*4.4*31);
            const energia_prom = (enero*0.78*4.4*31);
            const potencia_req = constantes.kilos*enero;
            const candidad_paneles = potencia_req/constantes.potencia_panel;
            const area_disp_nece = candidad_paneles*constantes.area_panel
            const precio_pagar_sin = cost_kvh*consumo;
            const valor_ahorrado_mensual = energia_prom*cost_kvh;
            const valor_ahorrado_anual = valor_ahorrado_mensual*12;
            const precio_pagar_con = precio_pagar_sin-valor_ahorrado_mensual;
            const porcentaje_aho = (valor_ahorrado_mensual/precio_pagar_sin)*100;
            let valor_esti = 0;
            if (potencia_req < 60000){
                valor_esti = potencia_req*constantes.valor_de_peso_1_60;
            }else if (potencia_req < 120000){
                valor_esti = potencia_req*constantes.valor_de_peso_61_120;
            } else {
                valor_esti = potencia_req*constantes.valor_de_peso_121;
            }

            const retorno_inv = valor_esti/valor_ahorrado_anual;

            const cotiza = {
                consumo_mensual_promedio: Math.round(1*consumo),
                porcentaje_de_ahorro: Math.round(porcentaje_aho),
                area_disponmible_necesaria: Math.round(area_disp_nece),
                potencia_requerida_para_el_ahorro: Math.round(potencia_req/1000),
                valor_ahorrado_mensual: Math.round(valor_ahorrado_mensual),
                valor_ahorrado_anual: Math.round(valor_ahorrado_anual),
                precio_a_pagar_con_energia: Math.round(precio_pagar_con),
                retorno_de_inversion: Math.round(retorno_inv),
                equivalente_a: Math.round(precio_pagar_sin),
                consumo_a_cubrir: Math.round(consumo_a_cubrir),
                valor_estimado: Math.round(valor_esti)  
            }

            const usuario= {name,mail,company,city,direccion,phone} 

            const lead = {
                lead:usuario,
                cotizacion:cotiza
            }            
            setResultados_(lead); 
            const col = collection(db,"cotizaciones")
            const generarlead = await addDoc(col,lead)

            const options = {                
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'X-MailerLite-ApiDocs': 'true',
                    'content-type': 'application/json',
                    'X-MailerLite-ApiKey': config_.token
                },
                body: JSON.stringify({
                    email: mail,
                    name: name,
                    fields: {
                        phone: phone,
                        company: company, 
                        city: city,
                        //Campos personalizados
                        consumo_mensual_promedio: Math.round(1*consumo),
                        porcentaje_de_ahorro: Math.round(porcentaje_aho),
                        area_disponmible_necesaria: Math.round(area_disp_nece),
                        potencia_requerida_para_el_ahorro: Math.round(potencia_req),
                        valor_ahorrado_mensual: Math.round(valor_ahorrado_mensual),
                        valor_ahorrado_anual: Math.round(valor_ahorrado_anual),
                        precio_a_pagar_con_energia: Math.round(precio_pagar_con),
                        retorno_de_inversion: Math.round(retorno_inv),
                        equivalente_a: Math.round(precio_pagar_sin),
                        consumo_a_cubrir: Math.round(consumo_a_cubrir),
                        valor_estimado: Math.round(valor_esti),
                        direccion: Math.round(direccion)
                    },
                    resubscribe: false,
                    autoresponders: true,
                    type: 'active'                
                })
            };

            const respuesta = await fetch('/api/v2/groups/'+config_.grupo_id+'/subscribers',options)            
            const data_ = await respuesta.json();
            console.log("Se envio "+data_.email+" a la base con el folio: "+generarlead.id)
        }
        catch (e) {
            alert(e);
        }
               
    }

        

    return(
        <Results.Provider value={{
            resultados,
            variablesU,
            novacio,
            resultados_,
            setVariblesU,
            setResultados,
            setNovacio,
            cotizacion_,
            setResultados_,
        }}>
            {children}
        </Results.Provider>
    )
}

export default ResultData