import React from "react";

const HeaderIngev = () => {
    return (
        <header>
            <a href="https://ingev.com.co/"><img className="logo-img" src="https://ingev.com.co/wp-content/uploads/2022/07/LOGO-300x244.png" width="100" height="81.33" alt="ingev-logo"/></a>
        </header>
    )
}

export default HeaderIngev